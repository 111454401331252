export default {
    init() {
        // JavaScript to be fired on all pages
        $(document).ready(function() {
            $('[data-fancybox]').fancybox({
                buttons: [
                    'zoom',
                    // 'share',
                    // 'slideShow',
                    'fullScreen',
                    'download',
                    // 'thumbs',
                    'close',
                ],
                video: {
                    tpl:
                      '<video class="fancybox-video" controls>' +
                      '<source src="{{src}}" type="{{format}}" />' +
                      'Your browser doesn\'t support HTML5 video' +
                      '</video>',
                    format: '', // custom video format
                    autoStart: true,
                },
            });
        });

        $('.slick-slider').css('opacity', '1');

        // JavaScript to be fired on the home page
        // Sort list/grid
        $('#list').click(function (event) {
            event.preventDefault();
            localStorage.setItem('display', 'list');
            $('#list').addClass('active');
            $('#grid').removeClass('active');
            fadeOutIn($('#downloads'));
            setTimeout(function () {
                $('#downloads .download-item').removeClass('download-item--grid').addClass('download-item--list');
            }, 150);
        });

        $('#grid').click(function (event) {
            event.preventDefault();
            localStorage.setItem('display', 'grid');
            $('#list').removeClass('active');
            $('#grid').addClass('active');
            fadeOutIn($('#downloads'));
            setTimeout(function () {
                $('#downloads .download-item').removeClass('download-item--list').addClass('download-item--grid');
            }, 150);
        });

        let display =  localStorage.getItem('display');
        if (display === 'list') {
            $('#list').addClass('active');
            $('#grid').removeClass('active');
            $('#downloads .download-item').removeClass('download-item--grid').addClass('download-item--list');
        } else if (display === 'grid') {
            $('#list').removeClass('active');
            $('#grid').addClass('active');
            $('#downloads .download-item').removeClass('download-item--list').addClass('download-item--grid');
        }

        $('.downloads-category').fadeIn();

    function fadeOutIn(target)
    {
        target.fadeOut(150);
        setTimeout(function () {
            target.fadeIn(150);
        }, 300)
    }

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
