export const templateDownloadsData = {
    init() {
        // JavaScript to be fired on all pages
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is f
        // ired
    },
};

